import { shallowRef } from 'vue'

const accountsMinimalRef = shallowRef([])
let loadingPromise = null

export function useAccountsMinimal () {
  if (loadingPromise === null) {
    loadingPromise = new Promise(resolve => {
      useNuxtApp().$res.fetch.accountsMinimal()
        .then(res => {
          if (Array.isArray(res) && res?.length > 0) {
            res.forEach(acct => {
              acct.value = acct.accountPlanId
              acct.text = acct.name
            })
            accountsMinimalRef.value = res
          } else {
            accountsMinimalRef.value = []
          }
          resolve(accountsMinimalRef.value)
        })
    })
  }

  // })

  return {
    accountsMinimalRef,
    accountsMinimal: () => accountsMinimalRef.value,
    accountsMinimalPromise: loadingPromise
  }
}
